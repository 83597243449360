<template>
  <div>
    <!-- 路由内容展示 -->
    <router-view />
  </div>
</template>

<script>
export default {
  created() {
    // 如果当前路径为空，则跳转到指定页面
    if (this.$route.path === '/') {
      this.$router.push('/'); // 你可以根据需要修改默认跳转的路径
    }
  }
}
</script>
