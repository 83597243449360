import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'picc',
    component: () => import(/* webpackChunkName: "picc" */ '../views/picc.vue')
  },
  {
    path: '/index',
    name: 'index',
    component: () => import(/* webpackChunkName: "index" */ '../views/index.vue')
  },
  {
    path: '/dy',
    name: 'dy',
    component: () => import(/* webpackChunkName: "dy" */ '../views/dy.vue')
  },
  {
    path: '/dytwo',
    name: 'dytwo',
    component: () => import(/* webpackChunkName: "dytwo" */ '../views/dytwo.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
