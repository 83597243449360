  import { createApp } from 'vue';
  import App from './App.vue';
  import router from './router';
  import store from './store';
  import 'lib-flexible/flexible';
  import Vant from 'vant';
  import 'vant/lib/index.css'; // 引入 Vant 样式
  import './assets/main.css'; // 引入全局样式

  // 引入 axios 和 qs
  import axios from 'axios';
  import qs from 'qs';

  // 创建 axios 实例
  const axiosInstance = axios.create({
    baseURL: `${window.location.origin}/api`, // 你的 API 基础 URL
    timeout: 10000, // 请求超时时间
    headers: {
      'Content-Type': 'application/json', // 默认请求头
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'brackets' }); // 使用 qs 进行参数序列化
    },
  });
  // 创建 Vue 应用
  const app = createApp(App);

  // 使用 Vant
  app.use(Vant);

  // 将 axios 实例挂载到全局属性中
  app.config.globalProperties.$axios = axiosInstance;

  // 挂载路由和 store
  app.use(store).use(router).mount('#app');
